hr {
  margin: 0;
  padding: 0;
}

.active_card {
  display: grid;
  border-radius: 20px;
  background-color: white;
  /* grid-template-columns: 300px; */



  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
}

.active_item {
  padding: 8px 16px 4px 32px;
}

.active_category {
  display: flex;
  justify-content: flex-start;
  padding: 8px 0px 0px 32px;
}

.text_bold {
  font-weight: 700;
}

.active_card1 {
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 8px 32px;
}

.active_card3 {
 margin-right: 1rem;
}

div.date {
  position: relative;
  border-radius: 8px;
  background-color: white;
  cursor: pointer;
  margin: 16px;
  height: 64px;
  padding-top: 10px;
}

div.date::before {
  content: "";
  position: absolute;
  inset: 0;
  border-radius: 8px;
  padding: 2px;
  background: linear-gradient(#fac105, #ffd814);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
}

.selected {
  background: linear-gradient(#fac105, #ffd814);
  border-radius: 8px;
}

.arrow_prev {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border: 2px solid #f5cc02;
  height: 64px;
  width: 48px;
}

.arrow_right {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 8px;
  border: 2px solid #f5cc02;
  height: 64px;
  width: 48px;
}

/* .arrow_padding {
  padding: 10px;
} */

/* table */

.users-table {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  margin: 50px;
  border-radius: 8px red;
  background: rgba(255, 255, 255, 0.0001);
  width: 100%;
}

.users-table td,
.users-table th {
  border: 1px solid #ddd;
  padding: 8px;
}

.users-table tr:nth-child(even) {
  background-color: #f2f2f2;
}

.users-table tr:hover {
  background-color: #ddd;
}

.users-table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #ffee99;
}

.table_cont {
  display: flex;
  justify-content: center;
}

/* day and date */
.i_day {
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 1vw;
}

.i_date {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 1vw;
}
.dateSlider{
  margin:16px 64px 0px;
}
.header_text{
  /* font-size: 24px; */
  /* font-weight: 700; */
}
.noOrder{
  color: grey;
}
.cardMainContainer{
  display: flex;
  justify-content: space-between;
  align-items: center;
}