.table-container {
    overflow-x: auto;
    margin: 20px;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
  }
  
  th, td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }
  
  th {
    background-color: #f2f2f2;
  }
  
  .new-loan {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
  }
  