.cont_1 {
  border-radius: 10px;

  background-color: #ffffff;
}
.card_button {
  display: flex;
  justify-content: center;
}
.card_order {
  padding-right: 50px;
  padding-left: 50px;
}
.card_text {
  text-align: justify;
}

/* planbutton2 */
.list-group-item {
  padding: 6px 12px;
  background-color: #fffceb;
}

.container {
  display: flex;
  justify-content: space-between;
}
div.container {
}
.cont_03 {
  padding: 16px;
}

/* plan  */
.plancontainer {
  display: flex;
  margin: 50px;
  column-gap: 50px;
}

.input-group {
  display: flex;
  align-content: stretch;
}

.input-group > input {
  flex: 1 0 auto;
}

.grid_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 10px;
}

.grid_box1 {
  grid-row: 1/3;
}
.grid_box2 {
  grid-row: 1/4;
}

.editplan_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 50px;
}
.editplan_gridbox {
  margin-top: 10px;
}
.editplan_innerbox {
  display: flex;
  justify-content: space-between;
}

/* button2 */
.button2 {
  background: #fffceb;
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  border: 2px solid #ffd814;
  border-radius: 0.5rem;
  padding: 0.5rem;
  height: 2.5rem;
}
