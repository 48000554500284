.privacy-policy {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    background-color: white;
  }
  
  .privacy-policy h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .privacy-policy h2 {
    font-size: 24px;
    margin-top: 40px;
    margin-bottom: 10px;
    color: #444;
  }
  
  .privacy-policy p {
    margin-bottom: 15px;
    line-height: 1.5;
  }
  
  .privacy-policy ul {
    margin-bottom: 15px;
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .privacy-policy a {
    color: #007bff;
  }
  
  .privacy-policy a:hover {
    color: #0056b3;
  }
  