@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@500;600;700&display=swap');



.buttonContainer {
  justify-content: center;
  align-items: center;
  display: flex;
  height: 50px;
  border-radius: 12px;
  border: 2px solid #FAC105;
  box-shadow: 0px 2px 2px #9E9E9E;
  cursor: pointer;
  background-color: white;

}
.buttonContainer:active {
  opacity: 0.5;
  background: linear-gradient(#FAC105, #FFD814)
}

.buttonText {
  font-weight: 900;
  font-size: 16px;
}

/* card */
.card {
  width: 270px;
  height: auto;
  margin: 10% auto;
  user-select: none;
  border-radius: 12px;
  border: none;
  overflow: hidden;

}

.card .image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 200px;
  border-radius: 16px 16px 0px 0px;


}

.cardname {
  margin: 16px;
  font-size: 16px;

}

.cardname>h4 {
  font-size: 20px;
}

.cardname1 {
  display: flex;
  align-items: center;
}
.itemText{
  font-size: 16px;
  /* color:red; */
  font-weight: 700;
}

.card-container {
  background-color: white;
  /* height: 300px; */
  box-shadow: 0px 2px 2px #9E9E9E;
  border-radius: 16px;
}


.inner-card-image {
  padding: 20px;
  font-size: 30px;
  text-align: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 200px;
  border-radius: 16px 16px 0px 0px;
}
.my-modal {
  /* width: 60vw ;   Occupy the 60% of the screen width */
  /* max-width: 90vw; */
  font-family: 'Dosis', sans-serif;
  
}