.customer_details {
  margin: 68px;
}
.grid_profile {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);
  margin-top: 8px;
}
.grid_profilebox1 {
  grid-column: 1/3;
}
.grid_profilebox2 {
  grid-column: 1/3;
}

grid_profilebox5 {
  grid-column: 1/3;
  margin-top: 8px;
}
.grid_profilebox6 {
  grid-column: 1/3;
}
.grid_profilebox7 {
  grid-column: 1/3;
  margin-top: 8px;
}
.grid_profilebox8 {
  grid-column: 1/3;
}

.grid_profilebox9 {
  grid-column: 1/3;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 50px;
}

.grid_profilebox1 {
  display: flex;
}

/* Latest Subscription */
.latest_sub {
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  grid-column-gap: 50px;
  grid-row-gap: 25px;

  background-color: white;
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
}
.latest_subscription1 {
  margin-top: 8px;
}
.latest_sub1 {
  grid-row: none;
}
.latest_sub2 {
  font-weight: 700;
}

.latest_sub5 {
  /* background-color: rgb(255, 149, 0); */
  grid-row: 1/8;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

/* additional */
.latest_token {
  /* background-color: aqua; */
}
.latest_token1 {
  border: 2px dashed #2f2c5d;
  padding: 10px;
  border-radius: 8px;
}
.latest_token2 {
  border: 2px dashed #2f2c5d;
  border-radius: 8px;
  padding: 10px;
}

/* PreviousSubscriptions */
.previous_subscriptions {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 50px;

}
.previous_sub1 {
  grid-row: 1/3;
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.previous_sub2 {
  display: flex;
  justify-content: flex-start;
}

.previous_sub5 {
  grid-row: span 2;
  text-align: center;
  margin-top: 16px;
  display: flex;
}

.previous_svg {
  margin-right: 8px;
  display: inline-block;
  vertical-align: middle;

}
.previous_style {
  display: flex;
  justify-content: flex-start;
  margin-top: 16px;

}
/* Drop down menu */
.dropdown{
  display: grid;
  grid-template-columns: repeat(5,1fr);
  grid-column-gap: 50px;
}
.dropdown_bold{
  display: grid;
  grid-template-columns: repeat(5,1fr);
  grid-column-gap: 50px;
  font-weight: 700;
}

#example-collapse-text{
  margin-top: 20px;
}
