
.faQuestions {
    background-color: white;
    padding: 0.8rem;
    margin-top: 0.5rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
    cursor: pointer;
}
.faqAnswer{
    background-color: white;
    margin-top: 0.5rem;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.16);
    border-radius: 8px;
}
.faQuestionsInnerContainer{
    display: flex;
    justify-content: space-between;
}
.faqAnswerInnerContainer{
    font-weight: bold;
    margin-bottom: 1rem;
}
