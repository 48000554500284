.main_body {
    padding: 20px;
    display: grid;
    grid-template-columns: auto auto ;
}



@media screen and (min-width : 320px) and (max-width : 480px) {
    #cardCarousel {
        padding: 30px 0px 0px 0px;
        margin-top:30px
    }
}



@media screen and (min-width : 1024px) {
    #cardCarousel {
        padding: 30px 150px 0px 150px
    }
}

@media screen and (min-width : 1284px) {
    #cardCarousel {
        padding: 30px 150px 0px 150px
    }
}
.main_body {
    overflow-y: auto; 
}
.fadeIn {
  opacity: 0;
  animation: fadeInAnimation 1s ease-in forwards;
}

@keyframes fadeInAnimation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* Delay the animation for each text element */

.text4{
  animation-delay: 1s;
}


.home-container {
  font-family: 'Arial', sans-serif;
}

.hero-section {
  padding: 80px 0;
  background-color: #f8f9fa;
}

.hero-content {
  padding-right: 30px;
}

.hero-title {
  font-size: 4rem;
  font-weight: 900;
  color: #333;
  margin-bottom: 20px;
}

.hero-subtitle {
  font-size: 2rem;
  font-weight: 700;
  color: #555;
  margin-bottom: 20px;
}

.hero-description {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 30px;
}

.get-app-button {
  width: 70%;
  background: linear-gradient(#FAC105, #FFD814);
  border: none;
  font-weight: bold;
  padding: 12px 24px;
  font-size: 1.1rem;
}

.section-title {
  font-size: 2.5rem;
  font-weight: 900;
  color: #333;
  text-align: center;
  margin: 60px 0 30px;
}

.menu-section {
  padding: 40px 0;
}

.about-section {
  padding: 40px 0;
  background-color: #f8f9fa;
}

.about-content {
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
  color: #555;
  line-height: 1.6;
}

.about-images {
  margin-top: 40px;
}

.about-image {
  width: 100%;
  height: auto;
  border: 2px solid #FFD814;
  border-radius: 8px;
  margin-bottom: 20px;
}

.links-section {
  text-align: center;
  padding: 40px 0;
}

.policy-link {
  display: inline-block;
  margin: 10px;
  color: #333;
  text-decoration: none;
  font-weight: bold;
}

.policy-link:hover {
  color: #FAC105;
}

.app-section {
  padding: 40px 0;
  background-color: #f8f9fa;
}

.app-description {
  text-align: center;
  color: #666;
  margin-bottom: 30px;
}

.app-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.faq-section {
  padding: 40px 0;
}

@media (max-width: 768px) {
  .hero-title {
    font-size: 3rem;
  }

  .hero-subtitle {
    font-size: 1.5rem;
  }

  .get-app-button {
    width: 100%;
  }
}