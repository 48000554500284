.order-history-container {
    padding: 20px;
    min-height: 100vh;
    width: 100%;
  }
  
  .date-filter-section {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .date-inputs {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .date-input-group {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .date-input-group label {
    font-weight: 500;
    color: #333;
  }
  
  .date-input-group input {
    padding: 8px 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .total-price {
    background-color: #f8f9fa;
    padding: 12px 20px;
    border-radius: 4px;
    font-weight: 600;
    color: #333;
  }
  
  .table-container {
    overflow-x: auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .order-table {
    width: 100%;
    border-collapse: collapse;
    table-layout: fixed;
  }
  
  /* Column widths */
  .order-table th:nth-child(1) { width: 5%; }  /* Sl.No */
  .order-table th:nth-child(2) { width: 8%; }  /* Order ID */
  .order-table th:nth-child(3) { width: 10%; } /* Delivery Date */
  .order-table th:nth-child(4) { width: 8%; }  /* Route */
  .order-table th:nth-child(5) { width: 10%; } /* Delivery User */
  .order-table th:nth-child(6) { width: 15%; } /* User Data */
  .order-table th:nth-child(7) { width: 18%; } /* Order Details */
  .order-table th:nth-child(8) { width: 18%; } /* Payment */
  .order-table th:nth-child(9) { width: 8%; }  /* Status */
  
  .order-table th {
    /* background: linear-gradient(to right, #2c3e50, #3498db); */
    /* color: white; */
    padding: 12px;
    text-align: left;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .order-table td {
    padding: 12px;
    border: 1px solid #ddd;
    vertical-align: top;
  }
  
  .user-data {
    display: grid;
    gap: 8px;
    font-size: 0.9rem;
  }
  
  .user-data-item {
    display: flex;
    flex-direction: column;
  }
  
  .user-data-label {
    font-weight: 500;
    color: #666;
    margin-bottom: 2px;
    font-size: 0.8rem;
  }
  
  .user-data-value {
    color: #333;
    word-break: break-word;
  }
  
  .order-details {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
  }
  
  .order-details li {
    padding: 8px;
    border-bottom: 1px solid #eee;
  }
  
  .order-details li:last-child {
    border-bottom: none;
  }
  
  .payment-details {
    display: flex;
    flex-direction: column;
    gap: 8px;
    font-size: 0.9rem;
  }
  
  .status-completed {
    color: #28a745;
    font-weight: 500;
  }
  
  .status-pending {
    color: #dc3545;
    font-weight: 500;
  }
  
  /* Responsive design */
  @media (max-width: 1200px) {
    .order-table {
      table-layout: auto;
    }
    
    .user-data {
      font-size: 0.85rem;
    }
    
    .user-data-label {
      font-size: 0.75rem;
    }
  }
  
  @media (max-width: 768px) {
    .date-filter-section {
      flex-direction: column;
      align-items: stretch;
    }
    
    .date-inputs {
      flex-direction: column;
    }
    
    .total-price {
      text-align: center;
    }
    
    .order-table {
      font-size: 0.8rem;
    }
    
    .user-data {
      font-size: 0.8rem;
    }
    
    .user-data-label {
      font-size: 0.7rem;
    }
  }