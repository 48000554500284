.app-container {
  background-color: #FFFCEB;
  padding-top: 60px; /* Add this line */
}

.navigation-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px; 
  background-color: #fff; 
  z-index: 1000;
}

.main-container {
  padding-top: 60px; 
}