.buttonContainer {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 50px;
    border-radius: 12px;
    border: 2px solid #FAC105;
    box-shadow: 0px 2px 2px #9E9E9E;
    cursor: pointer;

}
.buttonContainer:active {
    opacity: 0.5;
    background: linear-gradient(#FAC105, #FFD814)
}

.buttonText {
    font-weight: 900;
    font-size: 16px;
}